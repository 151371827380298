import logo from './logo.svg';
import './css/style.css';
import "./css/responsive.css";
// import "path/to/hamburgers";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ONEPAGE from './pages/ONEPAGE';
import Contact from './pages/Contact';
// import Erreur from "./pages/Erreur";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<ONEPAGE />}></Route>
        <Route path="/contact/" element={<Contact />}></Route>
        {/* <Route path="*" element={<Erreur />}></Route> */}
      </Routes>
    </BrowserRouter >
  );
}

export default App;
