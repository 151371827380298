import "../../css/responsive.css";
import React from 'react';
import {Row, Col } from "react-bootstrap";
import ThemeProvider from 'react-bootstrap/ThemeProvider';

const CustomBreakpoints = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};

const InfoEvent = ({lieux, type, date}) => {
    return (
        <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
            <Col md={12}>
                <Row className="justify-content-center">
                <Col lg={4} md={6} xs={8} className="d-flex flex-column text-start">
                    <b className="event-info">{lieux}</b>
                    <b>{type}</b>
                </Col>
                <Col lg={4} md={6} xs={8} className="desktop flex-d flex-column  text-end ">
                    <b className="event-info">{date}</b>
                </Col>
                <Col lg={4} md={6} xs={8} className="mobile flex-d flex-column  text-start ">
                    <b className="event-info">{date}</b>
                </Col>
                <Col lg={8} md={12} xs={8} className="d-flex flex-column text-center">
                    <hr className="event-separateur"/>
                </Col>
                </Row>
            </Col>
        </ThemeProvider>
    );
}



export default InfoEvent;

