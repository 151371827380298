import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import imgCTA1 from '../../assets/images/img-cta1.jpg'; // Importez l'image

const CTA_1 = () => {
    return (
        <section className="groupe-34 mt-5" id="cta1">
            <Container className="cta-container">
                <Row className="align-items-center">
                    <Col md={12} className="d-flex flex-column justify-content-center align-items-center p-4 text-overlay">
                        <p className="text-center nous-vous-remercions-container">
                            <span className="d-block nous-vous-remercions">
                            «Nous vous remercions de nous inspirer,
                            </span>
                            <span className="d-block nous-vous-remercions">
                                et de nous rendre plus créatifs à chaque fois.»
                            </span>
                        </p>
                        <i className="text-center florian-chiane1">Florian CHIANE</i>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CTA_1;
