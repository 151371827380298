import React from 'react';

const PresentationArtistes = ({ name, title, imgSrc, imgAlt }) => {
    return (
        <>
            <p className="florian-chiane-grant-container">
                <span className="florian-chiane2">{name}</span>
                <br />
                <span className="nous-vous-remercions">{title}</span>
            </p>
            <div className='groupe-38-child'>
                <img
                    className=""
                    alt={imgAlt}
                    src={imgSrc}
                />
            </div>
            
        </>
    );
}

export default PresentationArtistes;
