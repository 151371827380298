import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            alert('Veuillez compléter le reCAPTCHA.');
            return;
        }
        try {
            const response = await axios.post('https://orchestre-bpm.com//send-email.php', { ...formData, recaptchaToken });
            console.log(response.data);
            alert('Message envoyé avec succès!');
        } catch (error) {
            console.error(error);
            alert('Une erreur s\'est produite. Veuillez réessayer.');
        }
    };

    const handleRecaptcha = async () => {
        const token = await window.grecaptcha.execute('6LeyNJolAAAAABigbmzWlWKL6P0i5_DUzX5OP7QP', { action: 'submit' });
        setRecaptchaToken(token);
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col md={8}>
                    <p>POUR UNE SOIRÉE INOUBLIABLE ET DES SOUVENIRS EN MUSIQUE, NOTRE ORCHESTRE EST À VOTRE ÉCOUTE. CONTACTEZ-NOUS DÈS AUJOURD’HUI POUR CRÉER ENSEMBLE DES MOMENTS MAGIQUES.</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={8}>
                    <Row>
                        <Col md={12}>
                            <Form onSubmit={handleSubmit} className="contact-form">
                                <Form.Group controlId="formName">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formMessage">
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3 contact-form-bouton" onClick={handleRecaptcha}>
                                    Envoyer
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ContactForm;
