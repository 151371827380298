import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgEllipse from "../../assets/images/ellipse-22.svg";
import InfoEvent from "./InfoEvent";

const Event = () => {

  const eventsData = [
    { lieux: "Roquebillière", type: "DJ (Live)", date: "18/05/2024" },
    { lieux: "Roquebillière", type: "DJ (Live)", date: "08/06/2024" },
    { lieux: "Grave de Peille", type: "DJ", date: "21/06/2024" },
    { lieux: "Sigale", type: "DJ", date: "22/06/2024" },
    { lieux: "Figaret", type: "DJ (Live)", date: "28-29/06/2024" },
    { lieux: "Rousillon", type: "DJ Amélioré", date: "06/07/2024" },
    { lieux: "Valbonne", type: "Orchestre", date: "13/07/2024" },
    { lieux: "Palavas les flots", type: "Orchestre", date: "14/07/2024" },
    { lieux: "Pelasque", type: "Orchestre", date: "20-21/07/2024" },
    { lieux: "Lantosque", type: "DJ + Orchestre", date: "26-27/07/2024" },
    { lieux: "Ilonse", type: "Orchestre", date: "02-03/08/2024" },
    { lieux: "Toulon", type: "Orchestre", date: "04/08/2024" },
    { lieux: "Consegudes", type: "DJ", date: "10-11/08/2024" },
    { lieux: "Sigale", type: "Orchestre", date: "15/08/2024" },
    { lieux: "Sclos de contes", type: "DJ + Orchestre", date: "16-17/08/2024" },
    { lieux: "Sigale", type: "Orchestre", date: "18/08/2024" },
    { lieux: "Saint Jeannet", type: "Orchestre", date: "24/08/2024" },
    { lieux: "Saint Pierre", type: "Orchestre", date: "31/08/2024" },
    { lieux: "Puget Theniers", type: "Orchestre", date: "07/09/2024" }
  ];

  const parseDate = (dateString) => {
    const parts = dateString.split(/[-/]/);
    if (parts.length === 3) {
      return new Date(parts[2], parts[1] - 1, parts[0]);
    } else if (parts.length === 2) {
      // Format "dd-mm-yyyy dd-mm-yyyy"
      const [start, end] = dateString.split(" ");
      const startDate = new Date(...start.split('-').reverse());
      return startDate;
    }
    return new Date(dateString); 
  };


  const sortedEvents = eventsData.sort((a, b) => parseDate(a.date) - parseDate(b.date));

  const today = new Date();
  const futureEvents = sortedEvents.filter(event => parseDate(event.date) >= today);
  const pastEvents = sortedEvents.filter(event => parseDate(event.date) < today);

  const [isFuture, setIsFuture] = useState(true);
  const [count, setCount] = useState(4);
  const [reduce, setReduce] = useState(false);

  const [eventsToShow, setEventsToShow] = useState([]);

  useEffect(() => {
    const initialEvents = isFuture ? futureEvents.slice(0, count) : pastEvents.slice(0, count);
    setEventsToShow(initialEvents);
  }, [isFuture, count, futureEvents, pastEvents]); 
  

  const handleShowMore = () => {
    if (reduce) {
      setCount(4);
      setReduce(false);
    } else {
      const eventList = isFuture ? futureEvents : pastEvents;
      if (count + 4 >= eventList.length) {
        setCount(eventList.length);
        setReduce(true);
      } else {
        setCount(count + 4);
      }
    }
  };

  const toggleEvents = () => {
    setIsFuture(!isFuture);
    setCount(4);
    setReduce(false);
  };

  return (
    <section className="groupe-40" id="event">
      <Container>
        <Row className="mb-100px">
          <Col md={12} className="d-flex flex-column align-items-center text-center">
            <h1 className="event">Event</h1>
          </Col>
          <Col md={12} className="d-flex flex-column align-items-center text-center">
            <h2 className="calendrier">Calendrier</h2>
          </Col>
        </Row>
        <Row>
            <Col md={12} className="d-flex flex-column align-items-center text-center event-container-btn mt-3">
                <button className="btn-event" onClick={toggleEvents}>
                <i className={`fa scale-animation ${isFuture ? 'show-arrow' : 'hide-arrow'} fa-arrow-left ml-2`}></i>
                <b className="b">{isFuture ? 'Événements Futurs' : 'Événements Passés'}</b>
                <i className={`fa scale-animation ${isFuture ? 'hide-arrow' : 'show-arrow'} fa-arrow-right ml-2`}></i>
                {/* <img className="groupe-41-child" alt="" src={imgEllipse} /> */}
                </button>
            </Col>
          {eventsToShow.map((event, index) => (
            <Col md={12} key={index}>
              <InfoEvent lieux={event.lieux} type={event.type} date={event.date} />
            </Col>
          ))}
          <Col md={12} className="d-flex flex-column align-items-center text-center event-container-btn">
            <button className="groupe-41" onClick={handleShowMore}>
              <b className="b">{reduce ? '-' : '+'}</b>
              <img className="groupe-41-child" alt="" src={imgEllipse} />
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Event;
