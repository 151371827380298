import { Container, Row, Col } from "react-bootstrap";
import Informations from "./Informations";
import ContactForm from './ContactForm';

const ContactCorp = () => {
    return (
        <section id="Contact" className="pt-5">
            
                <Row>
                    <Col lg={12} className="mb-5">
                        <h1 className="contact-titre"><span>CONTACTEZ</span> <span>NOUS</span></h1>
                    </Col>
                    <Col lg={8}>
                        <ContactForm/>
                    </Col>
                    <Col lg={4}>
                        <Informations/>
                    </Col>
                </Row>
        </section>
    );
}

export default ContactCorp;