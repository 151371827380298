import React from 'react';
import { Container } from 'react-bootstrap';
import Navigation from '../general/nav';

const Header = () => {
  return (
    <header>
      <div className="container-banner">
        <div className="groupe-1">
          <Container>
            <Navigation />
            <div className="groupe-de-masques-3">
              <h1 className="bpmevents">BPMEvents</h1>
            </div>
          </Container>
        </div>
      </div>
    </header>
  );
}

export default Header;
