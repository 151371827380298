import React from 'react';
import {Row, Col } from "react-bootstrap";


const GalerieEvent = ({lieu,type,img, imgAlt}) => {
    return (
        <Col md={4} className="conatiner-galerie-event">
            <img src={img} alt={imgAlt} className="container-galerie-event-img"/>
            <div className="container-galerie-event-info">
                <h3>{lieu}</h3>
                <h4>{type}</h4>
            </div>

        </Col>
    );
}


export default GalerieEvent;