import Header from "../components/principale/Header";
import About from "../components/principale/About";
import CTA_1 from "../components/principale/CTA-1";
import Artistes from "../components/principale/Artistes";
import Event from "../components/principale/Event";
import Galerie from "../components/principale/Galerie";
import Footer from "../components/principale/Footer";


const ONEPAGE = () => {


  return (

    <div className="one-page">
      {/*Header start*/}
      <Header />
      {/*Header end*/}
      {/*About start */}
      <About />
      {/*About end */}
      {/*CTA-1 start*/}
      <CTA_1 />
      {/*CTA-1 end*/}
      {/* Nos artistes start */}
      <Artistes />
      {/* Nos artistes end */}
      {/* Event start */}
      <Event />
      {/* Event end */}
      {/*Galerie start*/}
      <Galerie />
      {/*Galerie end*/}
      {/*Footer start */}
      <Footer />
      {/*Footer end */}


    </div>
  );
};

export default ONEPAGE;
