import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const About = () => {
    return (
        <section className="about" id="about">
            <Container>
                <Row className="justify-content-center align-items-center text-center" style={{ minHeight: '50vh' }}>
                    <Col md="12">
                        <h1 className="la-synergie-entre-container">
                            <p className="la-synergie-entre">{`«La synergie entre le groupe et la foule `}</p>
                            <p className="la-synergie-entre">
                                est la meilleure sensation qui soit.»
                            </p>
                        </h1>
                        <i className="florian-chiane">Florian Chiane</i>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                      <h2 className="propos mb-5">À Propos</h2>
                      <h3 className="qui-sommes-nous mb-5">Qui sommes-nous ?</h3>
                      <div className="groupe-56">
                        <div className="une-quipe-dynamique-container groupe-30">
                          <p className="la-synergie-entre">{`Une équipe dynamique d’artistes professionnels, `}</p>
                          <p className="la-synergie-entre">{`talentueux et internationaux. Nous sommes `}</p>
                          <p className="la-synergie-entre">{`passionnés par les notes, les rythmes, les `}</p>
                          <p className="la-synergie-entre">{`symphonies, notre but est d’inspirer, de faire `}</p>
                          <p className="la-synergie-entre">{`rêver, de faire briller, de faire danser, de faire `}</p>
                          <p className="la-synergie-entre">rire.</p>
                          <p className="la-synergie-entre">&nbsp;</p>
                          <p className="la-synergie-entre">{`L’Orchestre BPM réunit : Chanteurs, Chanteuses, `}</p>
                          <p className="la-synergie-entre">{`Musiciens, Danseuses, Techniciens Sons & Lumières `}</p>
                          <p className="la-synergie-entre">{`et un DJ, dont la seule motivation est de vous faire `}</p>
                          <p className="la-synergie-entre">
                              passer une soirée inoubliable !
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="about-video-container">
                        <iframe className="groupe-31" width="560" height="315" src="https://www.youtube.com/embed/KcdURTMW8Xw?si=yNrXJQD8LWd-_mV1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                      <img className="trac-11-icon" alt="" src="/trac-11.svg" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default About;
