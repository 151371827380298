import Nav from "../components/general/nav"
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/principale/Footer";
import ContactCorp from "../components/contact/ContactCorp"

const Contact = () => {
    return (
        <div className="contact-bg">
            <Container>
                <Nav />
                <ContactCorp />
                <Footer />
            </Container>
        </div>
    );
}

export default Contact;