import { Container, Row, Col } from "react-bootstrap";
import GalerieEvent from "./GalerieEvent";
import imgTest from "../../assets/images/roquebilière_festin.jpg";


const Galerie = () => {
    return (
      <section className="groupe-42" id="galerie">
        <Container>
          <Row>
            <div className="container-titre-galerie">
              <h1 className="titre-galerie">GALERIE</h1>
            </div>
            <Col md={12}>
              <h2 className="dcouvrez-nos-derniers">DÉCOUVREZ Nos Derniers événements</h2>
            </Col>
          </Row>
          <Row className="galerie-row">
            <GalerieEvent
              lieu="Roquebillère"
              type="Festin"
              img={imgTest}
              imgAlt="Image Festin"
            />
            <GalerieEvent
              lieu="Roquebillère"
              type="Festin"
              img={imgTest}
              imgAlt="Image Festin2"
            />
            <GalerieEvent
              lieu="Roquebillère"
              type="Festin"
              img={imgTest}
              imgAlt="Image Festin3"
            />
            <GalerieEvent
              lieu="Roquebillère"
              type="Festin"
              img={imgTest}
              imgAlt="Image Festin4"
            />
            <GalerieEvent
              lieu="Roquebillère"
              type="Festin"
              img={imgTest}
              imgAlt="Image Festin5"
            />
          </Row>
        </Container>
      </section>
    );
}

export default Galerie;





